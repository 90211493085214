.container {
    display: flex;
    justify-content: flex-end; /* ロゴを右寄せにする */
    align-items: center;
}
  
.container img {
    width: 25%;
    height: 25%;
    margin: 0;
}

.explain{    
    top:140px;
}
