.Sidebar{
    height: 100%;
    width:15%;
    background-color:black;
    float: left;
    position: fixed;
}
.SidebarList{
    height:auto;
    padding:0;
    width:100%;
}
.SidebarList .row{
    width:100%;
    height:60px;
    background-color: black;
    display: flex;
    color:aliceblue;
    justify-content: center;
    align-items: center;
}
.SidebarList .row:hover{
    cursor:pointer;
    background-color:#3b627a ;

}
.row #icon{
    flex:30%;
    display:grid;
    place-items: center;
}
.row #title{
    flex:70%
}